<template>
  <el-main class="centerwrap">
    <div class="centerbox">
      <!-- <h2 class="gobackpage">
        <router-link tag="span" :to="{name:'teamsBook',params:{tid:dataIntro.book.Tid}}" v-if="dataIntro.book.Tid">
          <i class="iconlujingbeifen1 iconfont"></i>返回团队列表
        </router-link>
        <router-link tag="span" :to="{name:'bookAll'}" v-if="dataIntro.book.Tid==0">
          <i class="iconlujingbeifen1 iconfont"></i>返回图书列表
        </router-link>
      </h2> -->
      <div class="cardone">
        <div class="leftimg">
          <div class="imgbox">
            <img :src="dataIntro.book.Cover" alt />
          </div>
        </div>
        <div class="righttext">
          <h2 class="titleh2">
            <span class="text">{{dataIntro.book.Bookname}}</span>
            <router-link
              :to="dataIntro.book.Tid ? {name:'bookInfo',query:{Id:dataIntro.book.Id,tid:dataIntro.book.Tid}}: {name:'bookInfo',query:{Id:dataIntro.book.Id}}"
              tag="span"
              class="edit iconfont iconbianji2"
              v-if="dataIntro.iscreator==1"
            ></router-link>
          </h2>
          <p class="textp" v-html="dataIntro.book.Desc"></p>
          <div class="leftdl">
            <dl class="msg">
              <dt>作者：</dt>
              <dd class="black">{{dataIntro.author.name}}</dd>
            </dl>
            <dl class="msg">
              <dt>售价：</dt>
              <dd class="blue">￥{{Discount}}</dd>
              <dd class="dec" v-if="dataIntro.book.Price!==0">￥{{dataIntro.book.Price}}</dd>
            </dl>
            <dl class="msg" v-if="dataIntro.book.Price>0">
              <dt>已购买：</dt>
              <dd v-for="ite in dataIntro.buyer.users">
                <img :src="ite.avatar" alt />
              </dd>
              <dd>共{{dataIntro.buyer.count}}人</dd>
            </dl>
          </div>
          <div class="btnbox" v-if="dataIntro.isbuyer==1 || dataIntro.iscreator==1 || dataIntro.book.Price == 0">
            <router-link :to="{name:'bookDetail',params:{name:name}}" tag="span">
              <el-button size="medium" type="primary">阅读</el-button>
            </router-link>
            <!-- <el-button size="medium" class="bluebtn">推广奖励</el-button> -->
          </div>

          <div class="btnbox" v-else>
            <el-button type="primary" size="medium">
              <router-link tag="span" :to="{name:'pay',params:{Id:dataIntro.book.Id,type:1}}">购 买</router-link> </el-button>
            <router-link :to="{name:'bookDetail',params:{name:name}}" tag="span">
              <el-button size="medium" class="bluebtn">试 读</el-button>
            </router-link>
            <!-- <el-button size="medium" class="bluebtn">推广奖励</el-button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="centerbox2">
      <div class="tabnav">
        <h2
          v-for="(item,i ) in navText1"
          v-text="item"
          :class="i==tabIndex1? 'active' :''"
          @click="handelTab(i,'tabIndex1')"
        ></h2>
      </div>
      <div class="tabbox">
        <div v-show="tabIndex1==0">
          <div class="summary" v-html="dataIntro.book.Overview">
          </div>
        </div>
        <div v-show="tabIndex1==1">
          <el-tree 
          :data="dataIntro.section" 
          :props="defaultProps"
          node-key="Id" 
          default-expand-all
           :expand-on-click-node="false"
          :default-expanded-keys="[1, 2]" 
          >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <router-link :to="{name:'bookDetail',params:{name:name},query:{Id:data.Id}}" tag="span">
                {{ node.label }}
            </router-link>
             <i class="read" v-if="dataIntro.book.Price > 0 && data.Trials==1 && dataIntro.isbuyer==0">试读</i>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import { booksOverviewRequest } from "@/api/project.js";
export default {
  components: {},
  data() {
    return {
      dataIntro: {
        author: {},
        book: {},
        buyer: {},
        section: []
      },
      navText1: ["概述", "目录"],
      tabIndex1: 0,
      defaultProps: {
          children: 'Child',
          label: 'Section'
        },
      isAll:false,
      iscreator:0
    };
  },
  created() {
    this.getBookOverview();
  },
  computed: {
    name() {
      return this.$route.params.name;
    },
    Discount() {
      if (this.dataIntro.book.Price == 0) {
        return (this.dataIntro.book.Discount = "免费");
      } else {
        return ((this.dataIntro.book.Discount * this.dataIntro.book.Price) / 10).toFixed(2);
      }
    }
  },
  methods: {
    handelTab(index, a) {
      this[a] = index;
    },
    async getBookOverview() {
      try {
        const result = await booksOverviewRequest({
          name: this.name
        });
        if (result.status == 200) {
          if (result.data) {
            this.dataIntro.author = result.data.author || {};
            this.dataIntro.book = result.data.book || {};
            this.dataIntro.buyer = result.data.buyer || {};
            this.dataIntro.section = result.data.section || [];
            this.dataIntro.iscreator = result.data.iscreator || 0;
            this.dataIntro.isbuyer= result.data.isbuyer;
            
          }
        }
      } catch (err) {}
    }
  }
};
</script>
<style  lang="less" scoped>
.centerwrap {}
.centerbox {
        background: url("../../../assets/imgs/bookdetailbg.png") no-repeat;
    background-size: cover;
  padding: 0 0 30px 0;
  .gobackpage {
    padding: 18px 0 0 15px;
    font-size: 16px;
    color: #000000;
    line-height: 20px;
    height: 38px;
    box-sizing: border-box;
    cursor: pointer;
    i {
      margin-right: 10px;
    }
  }
  .cardone {
    width: 1200px;
    box-sizing: border-box;
    background: #fff;
    -webkit-box-shadow: 0px 0px 10px #e8e8e8;
    box-shadow: 0px 0px 10px #e8e8e8;
    padding: 34px;
    margin: 30px auto 0;
    display: flex;

    .leftimg {
      width: 195px;
      margin-right: 30px;
      .imgbox {
        width: 195px;
        height: 255px;
        border: 1px solid #c3d7ff;
        border-radius: 4px;
        overflow: hidden;
        padding: 5px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tittleh2 {
        font-size: 14px;
        color: #2f95e3;
        text-decoration: underline;
      }
    }
    .righttext {
      flex: 1;
      .titleh2 {
        font-size: 20px;
        margin-bottom: 10px;
        .text {
          min-width: 80px;
        }
        .iconfont {
          color: #11adcf;
          margin-left: 15px;
        }
      }
      .textp {
        font-size: 13px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; //溢出隐藏
        // margin-bottom: 15px;
      }
      .leftdl {
        float: left;
        margin-top: 20px;
      }
      .msg {
        margin-bottom: 10px;
        dt,
        dd {
          font-size: 14px;
          display: inline-block;
        }
        dd {
          color: #888;
          vertical-align: middle;
          margin-right: 10px;
        }
        .black {
          color: #222;
        }
        .blue {
          color: #3377ff;
        }
        .dec {
          text-decoration: line-through;
        }
        img {
          width: 20px;
          height: 20px;
          border: 1px solid #ccc;
          border-radius: 30px;
        }
      }
      .btnbox {
        margin: 75px 0 0;
        float: right;
        /deep/.el-button {
          margin-right: 10px;
        }
        .bluebtn {
          border: 1px solid #2f95e3;
          color: #2f95e3;
          font-weight: normal;
        }
      }
    }
  }
}
.centerbox2 {
  width: 1200px;
  margin: 0 auto;
}
/deep/.el-menu--horizontal {
  padding: 10px 0 0 0;
  margin: 10px 0 0;
  background: none;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 4px;
    height: 40px;
    line-height: 28px;
    font-size: 14px;
    color: #222;
    margin-right: 15px;
    &:focus,
    &:hover {
      background: none;
    }
    &.is-active {
      color: #16aed0;
    }
  }
}
.tabnav {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding: 10px 0 0 0;
  margin: 40px 0 0;
  h2 {
    display: inline-block;
    transition: border-color 0.3s, color 0.3s;
    margin-bottom: -1px;
    padding: 4px 4px;
    height: 40px;
    line-height: 28px;
    font-size: 14px;
    color: #222;
    margin-right: 15px;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
      color: #23b7e5;
      border-bottom: 2px solid #16aed0;
    }
  }
}
.tabbox {
  margin: 20px 0;
}
.summary {
  p {
    line-height: 26px;
    margin-bottom: 20px;
    font-size: 13px;
    color: #222;
  }
}
.el-tree {
  background: none;
}
.custom-tree-node{
  font-size: 14px;
  &::before{
  content:"";
  width:3px;
  height:3px;
  background: #ccc;
  margin: 10px 10px 10px 0;
  float:left;
  }
  .read{
    background:#EBF1FF;
        color: #3579FF;
    font-size: 10px;
    padding: 0 2px;
    border-radius: 4px;
    margin-left: 10px;
  }
}
/deep/.el-tree-node__content>.el-tree-node__expand-icon {
    margin-right: -15px;
    font-size: 15px;
  }
  .showall{
    text-align: center;
    i{
      font-size: 20px;
    color: #a0a0a0;
      cursor: pointer;
      
    }
  }
  .edit{
    cursor: pointer;
  }
</style>